import Header from './components/Header';
import RedirectionOptions from './components/RedirectionOptions';

function App() {
  return (
    <div className="flex flex-col h-screen">
      <header className="bg-coa-blue-dark text-white">
        <Header />
      </header>

      <main className="grow w-full max-w-prose mx-auto">
        <RedirectionOptions />
      </main>
    </div>
  );
}

export default App;
