const textContent = {
  es: {
    buttonText: 'Ver página en ashevillenc.gov',
    requestedResource:
      'La página que ha solicitado ya no está disponible en esta ubicación. Actualice sus marcadores y enlaces para utilizar la nueva URL que aparece a continuación.',
    title: 'Esta página se ha movido',
  },
  ru: {
    buttonText: 'Посмотреть страницу на сайте ashevillenc.gov',
    requestedResource:
      'Запрашиваемая вами страница больше не доступна в этом месте. Пожалуйста, обновите свои закладки и ссылки, чтобы использовать новый URL-адрес, указанный ниже.',
    title: 'Эта страница переехала',
  },
  uk: {
    buttonText: 'Переглянути сторінку на ashevillenc.gov',
    requestedResource:
      'Сторінка, яку ви запитували, більше не доступна за цією адресою. Будь ласка, оновіть свої закладки та посилання, щоб використовувати нову URL-адресу нижче.',
    title: 'Ця сторінка переміщена',
  },
  en: {
    buttonText: 'View page on ashevillenc.gov',
    requestedResource:
      'The page you have requested is no longer available at this location. Please update your bookmarks and links to use the new URL below.',
    title: 'This page has moved',
  },
};

export default textContent;
