import textContent from './text.js';

function filterLangCode(subdomain) {
  if (subdomain.includes('localhost')) {
    return ['es', 'ru', 'uk', 'en'][Math.floor(Math.random() * 4)];
  }
  return subdomain.replace('test-', '');
}

function getLocalizedText(code) {
  if (code === 'es') {
    return textContent.es;
  } else if (code === 'ru') {
    return textContent.ru;
  } else if (code === 'uk') {
    return textContent.uk;
  } else {
    return textContent.en;
  }
}

function getProdURL(code, path) {
  if (code === 'es') {
    return `https://www.ashevillenc.gov/es${path}`;
  } else if (code === 'ru') {
    return `https://www.ashevillenc.gov/ru${path}`;
  } else if (code === 'uk') {
    return `https://www.ashevillenc.gov/uk${path}`;
  } else {
    return `https://www.ashevillenc.gov${path}`;
  }
}

export { filterLangCode, getLocalizedText, getProdURL };
