import React from 'react';

function Button({ buttonAction, children }) {
  return (
    <button
      className="flex max-w-md items-center justify-center rounded-md my-8 overflow-hidden border-2 border-coa-blue-light text-xl p-6 bg-coa-blue-dark hocus:bg-white text-white hocus:text-coa-blue-dark font-light no-underline"
      onClick={buttonAction}
    >
      {children}
    </button>
  );
}

export default Button;
