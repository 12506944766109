import React from 'react';
import logo from '../../img/city-of-asheville-logo-white.svg';

function Header() {
  return (
    <div className="w-full flex flex-row justify-between items-center p-4">
      <a href="https://www.ashevillenc.gov">
        <img src={logo} width="240px" alt="City of Asheville logo" />
      </a>
    </div>
  );
}

export default Header;
