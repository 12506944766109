import React from 'react';
import Button from '../Button/Button.js';
import { filterLangCode, getLocalizedText, getProdURL } from '../../utils.js';

function RedirectionOptions() {
  const subdomain = window.location.origin.split('//')[1].split('.')[0];
  const requestedLangCode = filterLangCode(subdomain);
  const langObject = getLocalizedText(requestedLangCode);
  const prodURL = getProdURL(requestedLangCode, window.location.pathname);

  const [value, setValue] = React.useState('');

  function handleSubmit(event) {
    event.preventDefault();
    if (value.length === 0) {
      window.location.href = prodURL;
    }
  }

  function handleChange(e) {
    setValue(e.target.value);
  }

  const honeyStyle = {
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    height: 0,
    width: 0,
    zIndex: '-1',
  };

  return (
    <div className="my-16 mx-8 p-8 text-base lg:text-lg bg-white rounded-lg">
      <h1 className="text-xl md:text-2xl lg:text-3xl font-light border-b py-1">
        {langObject.title}
      </h1>
      <p className="my-4">{langObject.requestedResource}</p>
      <div className="my-4 break-words">{prodURL}</div>
      <form className="w-full" onSubmit={handleSubmit}>
        <input
          id="email"
          name="email"
          type="email"
          value={value}
          onChange={handleChange}
          style={honeyStyle}
        />
        <div className="w-full flex justify-center">
          <Button buttonAction={handleSubmit}>{langObject.buttonText}</Button>
        </div>
      </form>
    </div>
  );
}

export default RedirectionOptions;
